.content-para {
    padding-left: 30px;
    padding-right: 530px;
    padding-top: 10px;
    color: gray;
    width: 100%;
}

.csh {
    color: #086328 !important;
}
.mission-area {
    padding: 20px;
    padding-bottom: 60px !important;
}
.work-content {
    color: rgb(124, 116, 116) !important;
}
.work-content h5{
    font-size: 1.1rem !important;

}
.read-more {
    color: #69bb6f !important;
}

.card-workarea {
    padding: 40px 15px 15px;
    background-color: #69bb6f;
    border: 1px solid #4053A0;
    border-radius: 7px;
    height: 100% !important;
    transition: 0.4s;
}

.heading-wrap {
    margin-bottom: 45px;
}

.workarea-img {
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    margin-bottom: 22px;


}

.read-more {
    margin-top: 10px;
    display: inline-block;
    font-weight: 500;
    color: #002147;
}

.work-card-para {
    color: black;
    font-size: 14px;
    overflow: hidden;
}

.work-card-para:hover {
    color: white;
}

.mission-area {
    background: url(https://png.pngtree.com/back_origin_pic/03/65/73/341ab63409afde565d7f5bf58c9b6275.jpg);
    background-size: cover;
}

.heading-wrap {
    row-gap: 40px !important;
}

.card-workarea:hover {
    background-color: #69bb6f;
    color: white !important;
    border: 0;
}

.banner-down-content:hover {
    background-color: #69bb6f;

}

/* offers.css */
.DKV0Md{

    visibility: hidden !important;
}
.offer-left-part{
    padding-top: 100px;
}
.offer-main-head h1{
    color: #086328 !important;
}
.offer-box h1{
    color: white !important;
}

.offer-box .card{
    background-color: rgb(173, 173, 232) ;
}
.Member-detail{
    display: flex;
}


.form-card {
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .form-media, .form-body {
    display: flex;
    align-items: stretch;
  }
  
  .form-media {
    overflow: hidden;
    border-radius: 15px 0 0 15px;
  }
  
  .form-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px 0 0 15px;
  }
  
  .form-body {
    flex: 1;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 0 15px 15px 0;
  }
  
  button {
    font-weight: bold;
  }
  
  .form-card .form-label {
    font-size: 14px;
  }
  
  .form-card .form-control {
    font-size: 14px;
    padding: 5px;
  }
  